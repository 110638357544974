import {Suspense, useState} from 'react'
import React, {useCallback, useMemo} from 'react'
import * as d3shape from 'd3-shape'
import {scaleLinear} from '@visx/scale'
import {Drag, raise} from '@visx/drag'
import {AdvancedSearchPayload, Listing} from '~/api/types'
import {localPoint} from '@visx/event'
import {LinePath} from '@visx/shape'
import {MouseTouchOrPointerEvent} from '@visx/drag/lib/useDrag'
import {Point} from '@visx/point'
import {cn, useSize} from '~/lib/utils'
import styles from './AdvancedSearch.module.scss'
import chartStyles from '~/components/Chart.module.scss'
import {namedColours} from '~/lib/colorCycle'
import StocksTable from '~/components/StocksTable'
import {useDebounceValue} from 'usehooks-ts'
import {post} from '~/api/query'
import {useSuspenseQuery} from '@tanstack/react-query'
import Loading from '~/components/Loading'
import {Select} from '~/components/ui/select'
import {useHistoryState} from '~/hooks/useHistoryState'
import {ColumnDef} from '@tanstack/react-table'
import {ChartBackground} from '~/components/Axis'

export const AdvancedSearch = () => {
    const [searchQuery, setSearchQuery] = useHistoryState<AdvancedSearchPayload>(
        'advanced-search',
        {
            type: 'advanced_search',
            metric: 'REVENUE',
            shape: ['0', '0', '0', '0', '0'],
        },
    )

    const [debouncedQuery] = useDebounceValue(searchQuery, 500)

    return (
        <>
            <div className="mb-8 flex flex-1 flex-col items-center gap-6">
                <h1 className="text-center">Advanced Search</h1>
                <QueryByShape searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            </div>
            <h2>Results</h2>
            <Suspense fallback={<Loading />}>
                <SearchResults query={debouncedQuery} />
            </Suspense>
        </>
    )
}

const SearchResults = ({query}: {query: AdvancedSearchPayload}) => {
    const endpoint = '/search/advanced-search'

    const {data} = useSuspenseQuery({
        queryKey: [endpoint, query],
        queryFn: () => post(endpoint, {payload: query}),
    })

    const extraColumn = useMemo<ColumnDef<Listing>>(
        () => ({
            header: 'Preview',
            cell: ({row}) => (
                <StockMetricPreviewChart
                    key={row.original.symbol + row.index}
                    shape={data.listings_with_metrics[row.index].shape}
                    metric={query.metric}
                />
            ),
            meta: {
                className: 'min-w-28',
            },
        }),
        [data],
    )

    return (
        <StocksTable
            listings={data.listings_with_metrics.map(l => l.listing)}
            extraColumn={extraColumn}
        />
    )
}
type Metric = NonNullable<AdvancedSearchPayload['metric']>

const filterableMetricConfig = {
    REVENUE: {label: 'Revenue', color: namedColours.green},
    GROSS_REVENUE: {label: 'Gross Revenue', color: namedColours.green},
    OPERATING_EXPENSES: {label: 'Expenses', color: namedColours.red},
    NET_PROFIT: {label: 'Net Profit', color: namedColours.purple},
    OPERATING_INCOME: undefined, // Not supported for aesthetic reasons
} satisfies Record<Metric, {label: string; color: string} | undefined>

export const QueryByShape = ({
    searchQuery,
    setSearchQuery,
}: {
    searchQuery: AdvancedSearchPayload
    setSearchQuery: (payload: AdvancedSearchPayload) => void
}) => {
    const [localQuery, setLocalQuery] = useState({
        type: 'advanced_search' as const,
        metric: searchQuery.metric ?? ('REVENUE' as Metric),
        shape: searchQuery.shape.map(parseFloat) ?? [0, 0, 0, 0, 0],
    })

    React.useEffect(() => {
        const isValid =
            localQuery.metric &&
            localQuery.shape?.every(val => !isNaN(val) && val >= -1 && val <= 1) &&
            !localQuery.shape?.every(val => val == 0)
        if (isValid) {
            setSearchQuery({
                ...localQuery,
                shape: localQuery.shape.map(String),
            })
        } else {
            setSearchQuery({...searchQuery, shape: ['0', '0', '0', '0', '0']})
        }
    }, [localQuery])

    return (
        <>
            <Select
                className="w-72 sm:w-72"
                label="Metric"
                value={localQuery.metric ?? 'REVENUE'}
                onChange={newMetric => setLocalQuery({...localQuery, metric: newMetric})}
                values={Object.entries(filterableMetricConfig)
                    .filter(([_key, config]) => !!config)
                    .map(([key, config]) => ({
                        value: key as Metric,
                        label: config!.label,
                    }))}
            />
            <div className="w-full max-w-sm sm:max-w-lg">
                <ShapeEditChart
                    shape={localQuery.shape}
                    metric={localQuery.metric}
                    setShape={newShape =>
                        setLocalQuery({
                            ...localQuery,
                            shape: newShape,
                        })
                    }
                />
            </div>
        </>
    )
}
/*
 * ShapeEditChart is an editable line chart,
 * where the points can be dragged with mouse to change the y axis values.
 **/
const ShapeEditChart: React.FC<{
    shape: number[]
    setShape: (newShape: number[]) => void
    metric: Metric
}> = ({shape, setShape, metric}) => {
    const dimensionsRef = React.useRef<HTMLDivElement>(null)
    const {width, height} = useSize(dimensionsRef, 200)

    const chartPadding = 20

    const xScale = scaleLinear<number>({
        domain: [0, shape.length - 1],
        range: [chartPadding, width - chartPadding],
    })

    const yScale = scaleLinear<number>({
        domain: [-1, 1],
        range: [height - chartPadding, chartPadding],
    })

    const chartData = () =>
        shape.map((value, index) => ({
            value,
            index,
            x: xScale(index),
            y: yScale(value),
        }))

    const [dragShapeData, setDragShapeData] = React.useState(chartData())

    React.useEffect(() => {
        setDragShapeData(chartData())
    }, [width, height])

    const yScaleRange = yScale.range().map(x => x)

    const onDragMove = useCallback(
        (event: MouseTouchOrPointerEvent, targetIndex: number) => {
            const point = localPoint(event) ?? new Point({x: 0, y: 0})

            if (point.y > yScaleRange[0]) {
                point.y = yScaleRange[0]
            }
            if (point.y < yScaleRange[1]) {
                point.y = yScaleRange[1]
            }

            // add the new point to the current line
            const nextShape = [...shape]
            const newShape = yScale.invert(point.y) ?? 0
            nextShape[targetIndex] = Math.round(newShape * 10000) / 10000
            setShape(nextShape)
        },
        [setShape, yScaleRange],
    )

    const color = filterableMetricConfig[metric]!.color

    return (
        <div ref={dimensionsRef} className="relative mx-auto w-full">
            <svg
                className={chartStyles.chartSvg}
                width={width}
                height={height}
                style={{
                    touchAction: 'none', // Disable scrolling on touch, mobile devices
                }}
            >
                <ChartBackground width={width} height={height} yScale={yScale} numTicks={4} />
                <line
                    x1={0}
                    x2={width}
                    y1={height / 2}
                    y2={height / 2}
                    strokeWidth="2"
                    stroke="#FFF7"
                />
                <LinePath
                    className={styles.line}
                    curve={d3shape.curveMonotoneX}
                    stroke={color}
                    data={chartData()}
                    x={d => xScale(d.index)}
                    y={d => yScale(d.value)}
                    shapeRendering="geometricPrecision"
                />
                {dragShapeData.map(({index, x, y}) => (
                    <Drag
                        key={index}
                        width={width}
                        height={height}
                        x={x}
                        y={y}
                        restrict={{
                            // Restrict to only y axis dragging
                            xMin: x,
                            xMax: x,
                            yMin: yScaleRange[1],
                            yMax: yScaleRange[0],
                        }}
                        onDragStart={() => {
                            // move the data item to end of the array for it to be drawn "on top of" the other data items
                            setDragShapeData(raise(dragShapeData, index))
                        }}
                        onDragMove={({event}) => onDragMove(event, index)}
                        onDragEnd={({event}) => onDragMove(event, index)}
                    >
                        {({dragStart, dragEnd, dragMove, isDragging, x, y, dx, dy}) => (
                            <React.Fragment key={index}>
                                {/* Visible point */}
                                <circle
                                    className={styles.sparkPoint}
                                    r={isDragging ? 7 : 5}
                                    transform={`translate(${dx}, ${dy})`}
                                    cx={x}
                                    cy={y}
                                    fill={color}
                                />
                                {/* Invisible drag target */}
                                <circle
                                    r={15}
                                    transform={`translate(${dx}, ${dy})`}
                                    cx={x}
                                    cy={y}
                                    fill="transparent"
                                    onMouseMove={dragMove}
                                    onMouseUp={dragEnd}
                                    onMouseDown={dragStart}
                                    onTouchStart={dragStart}
                                    onTouchMove={dragMove}
                                    onTouchEnd={dragEnd}
                                    style={{cursor: isDragging ? 'grabbing' : 'grab'}}
                                />
                            </React.Fragment>
                        )}
                    </Drag>
                ))}
            </svg>
        </div>
    )
}

/* Small chart in the table row, to preview the shape */
const StockMetricPreviewChart: React.FC<{shape: string[]; metric: Metric}> = ({shape, metric}) => {
    const width = 100
    const height = 30
    const chartPadding = 4

    const values = shape.map(parseFloat)

    const xScale = scaleLinear<number>({
        domain: [0, shape.length - 1],
        range: [chartPadding, width - chartPadding],
    })

    const yScale = scaleLinear<number>({
        domain: [Math.min(...values), 1],
        range: [height - chartPadding, chartPadding],
    })

    const color = filterableMetricConfig[metric]!.color

    return (
        <div className="relative mx-auto w-full">
            <svg width={width} height={height} className={chartStyles.chartSvg}>
                <ChartBackground
                    width={width}
                    height={height}
                    yScale={yScale}
                    strokeDasharray="2"
                    numTicks={2}
                />
                <LinePath
                    className={cn(styles.line, styles.thin)}
                    curve={d3shape.curveMonotoneX}
                    stroke={color}
                    data={shape.map((value, index) => ({value, index}))}
                    x={d => xScale(d.index)}
                    y={d => yScale(parseFloat(d.value))}
                    shapeRendering="geometricPrecision"
                />
            </svg>
        </div>
    )
}
